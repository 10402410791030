import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import './App.css';

function App() {
  const [dob, setDob] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [age, setAge] = useState(null);

  useEffect(() => {
    // Load ads after component mounts
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const calculateAge = () => {
    if (!dob || !targetDate) return;

    const birthDate = dayjs(dob);
    const endDate = dayjs(targetDate);
    const years = endDate.diff(birthDate, 'year');
    const months = endDate.diff(birthDate.add(years, 'year'), 'month');
    const days = endDate.diff(birthDate.add(years, 'year').add(months, 'month'), 'day');

    const totalDays = endDate.diff(birthDate, 'day');
    const weeks = Math.floor(totalDays / 7);
    const daysRemainder = totalDays % 7;

    const totalHours = totalDays * 24;
    const totalMinutes = totalHours * 60;
    const totalSeconds = totalMinutes * 60;

    setAge({
      years,
      months,
      days,
      totalMonths: years * 12 + months,
      totalDays,
      totalWeeks: weeks,
      remainingDays: daysRemainder,
      totalHours,
      totalMinutes,
      totalSeconds,
    });
  };

  return (
    <div className="App">
      <h1>Age Calculator</h1>

      <label>Date of Birth:</label>
      <input
        type="date"
        value={dob}
        onChange={(e) => setDob(e.target.value)}
      />

      <label>Age at the Date of:</label>
      <input
        type="date"
        value={targetDate}
        onChange={(e) => setTargetDate(e.target.value)}
      />

      <button onClick={calculateAge}>Calculate</button>

      {age && (
        <div className="result">
          <h2>Age:</h2>
          <p>{age.years} years {age.months} months {age.days} days</p>
          <p>or {age.totalMonths} months</p>
          <p>or {age.totalWeeks} weeks {age.remainingDays} days</p>
          <p>or {age.totalDays} days</p>
          <p>or {age.totalHours} hours</p>
          <p>or {age.totalMinutes} minutes</p>
          <p>or {age.totalSeconds} seconds</p>
        </div>
      )}

      {/* Google AdSense Ad Unit */}
      <div dangerouslySetInnerHTML={{
        __html: `
          <ins class="adsbygoogle"
               style="display:block"
               data-ad-client="ca-pub-4449321347922269"
               data-ad-slot="6687968014"
               data-ad-format="auto"
               data-full-width-responsive="true"></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        `,
      }} />
    </div>
  );
}

export default App;
